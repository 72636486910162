<template>
  <v-card>
    <v-card-title>{{ `${$t('menu.configuration')} /  ${$t('landing.evento')} / ${getTitle(event)}` }}</v-card-title>
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          md="2"
        >
          <v-btn
            color="error"
            class="mb-4 me-3"
            @click="$router.push({ name: 'events-list' })"
          >
            <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
            <span>{{ $t('btn.back') }}</span>
          </v-btn>
        </v-col>
      </v-row>
      <v-row
        class="match-height"
      >
        <!-- Multiple Column -->
        <v-col cols="12">
          <v-card-text>
            <v-form class="multi-col-validation">
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-tabs v-model="pos">
                    <v-tab
                      v-for="(time, indT) in event.times"
                      :key="indT"
                    >
                      {{ $moment(time.date).locale($i18n.locale).format('ddd') }}
                      <br />
                      {{ $moment(time.date).locale($i18n.locale).format('D/MMM') }}
                    </v-tab>
                  </v-tabs>

                  <v-divider></v-divider>

                  <v-tabs-items v-model="pos">
                    <v-tab-item
                      v-for="(time, indTi) in event.times"
                      :key="indTi"
                    >
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th
                                width="5%"
                                style="text-align: center;"
                              >
                              </th>
                              <th
                                class="text-uppercase"
                                width="5%"
                              >
                                {{ $t('landing.eventSoli.time') }}
                              </th>
                              <th
                                style="text-align: center;"
                              >
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(iten, index) in time.times"
                              :key="index"
                            >
                              <td style="text-align: center;">
                                <!--<v-tooltip
                                  v-if="permisos.includes('events:edit') && !iten.busy"
                                  top
                                  color="error"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      icon
                                      v-bind="attrs"
                                      @click="bloquearHora(iten, time.date)"
                                      v-on="on"
                                    >
                                      <v-icon color="error">
                                        mdi-cancel
                                      </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>{{ $t('landing.eventSoli.bloquearDay') }}</span>
                                </v-tooltip>

                                <v-tooltip
                                  v-if="permisos.includes('events:edit') && iten.busy"
                                  top
                                  color="success"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      icon
                                      v-bind="attrs"
                                      @click="bloquearHora(iten, time.date)"
                                      v-on="on"
                                    >
                                      <v-icon color="success">
                                        mdi-check
                                      </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>{{ $t('landing.eventSoli.desbloquearDay') }}</span>
                                </v-tooltip>-->
                              </td>
                              <td>
                                {{ iten.time }}
                              </td>
                              <td>
                                <span
                                  v-if="iten.cita"
                                >
                                  <span v-if="iten.cita.confirmada === true">
                                    <span :style="iten.cita.procesada === false ? 'color: red' : ''">

                                      {{ `${iten.cita.name}, ${iten.cita.rol}` }}
                                      -
                                      {{ iten.cita.company }} <span v-if="iten.cita.site_web"><a
                                        :href="iten.cita.site_web"
                                        target="_blank"
                                      >{{ iten.cita.site_web }}</a></span>

                                      <v-tooltip
                                        v-if="!iten.cita.procesada"
                                        bottom
                                      >
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-btn
                                            color="error"
                                            icon
                                            v-bind="attrs"
                                            @click="procesarCita(iten.cita)"
                                            v-on="on"
                                          >
                                            <span>
                                              <v-icon>
                                                mdi-square-edit-outline
                                              </v-icon>
                                            </span>
                                          </v-btn>
                                        </template>
                                        <span>{{ $t('btn.edit') }}</span>
                                      </v-tooltip>
                                    </span>
                                  </span>
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-tab-item>
                  </v-tabs-items>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card-text>

    <v-dialog
      v-model="isDialogBloqueo"
      scrollable
      max-width="350px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('landing.eventSoli.bloquearDay')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <p class="mt-5">
            {{ $t('landing.eventSoli.msgBloquearDay', { n: timeEdit.time }) }}
          </p>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogBloqueo = !isDialogBloqueo"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loading"
            @click="confirmBusyTimeEvent()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--DEFINIR ESTADO DE CITA-->
    <v-dialog
      v-model="isShowCita"
      scrollable
      max-width="350px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ citaEdit.name }} {{ citaEdit.company }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              {{ $t('landing.eventSoli.date') }}: {{ $moment(citaEdit.date).locale($i18n.locale).format('ddd, D MMM') }}
              <br />
              {{ $t('landing.eventSoli.time') }}: {{ citaEdit.time }}
            </v-col>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="citaEdit.agendar"
                :items="agendarOps"
                :label="$t('landing.eventSoli.cita')"
                outlined
                dense
                item-text="name"
                item-value="slug"
                hide-details="auto"
              >
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isShowCita = !isShowCita"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loading"
            @click="confirmAgendar()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiInformation,
} from '@mdi/js'
import Events from '../utils/Events.vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Events,
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      isDialogBloqueo: false,
      loading: false,
      timeEdit: {},
      citaEdit: {},
      isShowCita: false,
      event: {
        title: '',
        dateFrom: '',
      },
      agendarOps: [],
      qr: null,
      imgs: [],
      img: null,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiInformation,
      },
      model: {},
      id_model: -1,
      permisos: localStorage.getItem('permisos_auth'),
      tipos_fiscal: [],
      tipos_cuentas_bancarias: [],
      monedas: [],
      countries: [],
      paisUser: {},
      pos: 0,
      payMents: [],
      swiftRules: [
        // v => !!v || 'Campo requerido',

        // v => v.length <= 8 || 'Swift no válido',
        v => /[0-9a-zA-Z]{8}/.test(v) || 'Swift no válido',
      ],
    }
  },
  computed: {
    ...mapState({
      emailsReserve: state => state.app.emailsReserve,
      ctasBancarias: state => state.app.ctasBancarias,
      dataBancaria: state => state.app.dataBancaria,
      posCotizador: state => state.app.posCotizador,
    }),
  },
  created() {
    // this.pos = this.posCotizador
    this.pos = 0

    this.getEvent()
    this.getQR()
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/events/event1.svg'))
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/events/event2.svg'))
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/events/event3.svg'))
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/events/event4.svg'))

    this.agendarOps.push({
      name: 'Confirmar',
      slug: 'confirmar',
    })
    this.agendarOps.push({
      name: 'Denegar',
      slug: 'denegar',
    })
  },
  methods: {
    ...mapMutations([
      'addEmailAdminReserve',
      'updateEmailAdminReserve',
      'addCtasBancarias',
      'updateCtasBancarias',
      'setPosCotizador',
      'updateDataBancaria',
    ]),
    getTitle(item) {
      if (this.$i18n.locale === 'es') {
        return item.title
      }

      return item[`title_${this.$i18n.locale}`]
    },
    getDescripcion(item) {
      if (this.$i18n.locale === 'es') {
        return item.description
      }

      return item[`description_${this.$i18n.locale}`]
    },
    getEvent() {
      this.axios
        .post('catalogs/show-event-front', { slug: this.$route.params.slug })
        .then(res => {
          if (res.data.success === false) {
            this.$router.push({ name: 'error-404' })
          } else {
            this.event = res.data.data.item

            this.isLoading = false
          }
        })
        .finally(() => {
          this.shuffleDeck()
        })
    },
    getQR() {
      this.axios
        .post('catalogs/qr-event', { slug: this.$route.params.slug })
        .then(res => {
          this.qr = res.data
        })
    },
    shuffleDeck() {
      const numbers = [...this.imgs]
      let first
      let second
      let temp
      const count = numbers.length
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 10; i++) {
        first = Math.floor(Math.random() * count)
        second = Math.floor(Math.random() * count)
        temp = numbers[first]
        numbers[first] = numbers[second]
        numbers[second] = temp
      }
      this.imgs = numbers
      if (this.event.logo) {
        this.img = this.urlS3 + this.event.logo
      } else {
        // eslint-disable-next-line prefer-destructuring
        this.img = this.imgs[0]
      }
    },

    bloquearHora(time, date) {
      this.timeEdit = time
      this.timeEdit.date = date
      this.isDialogBloqueo = true
    },
    confirmBusyTimeEvent() {
      this.loading = true
      this.timeEdit.busy = !this.timeEdit.busy
      const json = {
        slug: this.$route.params.slug,
        ...this.timeEdit,
      }

      this.axios
        .post('events/update-busy-time-event', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(per => {
          if (per.data.success) {
            this.$toast.success(this.$t('msg.infoSuccess'))
          } else {
            this.$toast.error(per.data.message)
          }
        })
        .finally(() => {
          this.loading = false
          this.isDialogBloqueo = false
          this.getEvent()
        })
    },
    procesarCita(cita) {
      this.citaEdit = {
        agendar: '',
        name: cita.name,
        company: cita.company,
        rol: cita.rol,
        uiid: cita.uiid,
        date: cita.date,
        time: cita.time,
        event_id: this.event.id,
      }
      this.isShowCita = true
    },
    confirmAgendar() {
      if (this.citaEdit.agendar) {
        this.loading = true
        this.axios
          .post('events/update-agendar-event', this.citaEdit, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(per => {
            if (per.data.success) {
              this.$toast.success(this.$t('msg.infoSuccess'))
            }
          })
          .finally(() => {
            this.loading = false
            this.isShowCita = false
            this.getEvent()
          })
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
  },
}
</script>
<style>
/**PARA OCULTAR LA FECHA DE HOY EN EL COMPONENTE DE HORA */
.vc-date-time .vc-date .vc-weekday,
.vc-date-time .vc-date .vc-month,
.vc-date-time .vc-date .vc-day,
.vc-date-time .vc-date .vc-year {
  display: none;
}
</style>
